"use client"
import PushNotificationLayout from '../FirebaseNotification/PushNotificationLayout';
import Chat from './Chat';

const Messages = () => {

   
    return (
        <PushNotificationLayout>
            <Chat />
        </PushNotificationLayout>
    )
}

export default Messages
