import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { orderCartDetailsReset } from '../redux/orderCartDetails';
import { deliveryAddressReset } from '../redux/DeliveryAddress';
import { resetStatePromo } from '../redux/Promocode';
import { resetState } from '../redux/cart';

const PaymentStatus = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [paymentSuccess, setPaymentSuccess] = useState(null);

    const queryParams = new URLSearchParams(location.search);
    const queryParamsObj = {};

    for (const [key, value] of queryParams.entries()) {
        queryParamsObj[key] = value;
    }

    useEffect(() => {
        if (queryParamsObj.status === 'successful' || queryParamsObj.payment_status === 'Completed') {
            setPaymentSuccess(true);
            // toast.success("Payment Successful");
            dispatch(resetState());
            dispatch(orderCartDetailsReset());
            dispatch(deliveryAddressReset());
            dispatch(resetStatePromo());
        } else {
            setPaymentSuccess(false);
            // toast.error("Payment Failed");
            dispatch(resetState());
            dispatch(orderCartDetailsReset());
            dispatch(deliveryAddressReset());
            dispatch(resetStatePromo());
        }
    }, [location]);

    const handleOrderDetailsClick = () => {
        navigate("/profile/booking/services/" + queryParamsObj.order_id);
    };

    const handleHomeClick = () => {
        navigate("/");
    };

    return (
        <div>
            <div className="container">
                <div className="payment-status">
                    {paymentSuccess === true && (
                        <div className="payment-success">
                          
                            <h2>Payment Successful!</h2>
                            <p>Thank you for your payment. Your order has been confirmed.</p>
                        </div>
                    )}
                    {paymentSuccess === false && (
                        <div className="payment-fail">
                            <h2>Payment Failed</h2>
                            <p>There was an issue processing your payment. Please try again.</p>
                        </div>
                    )}
                    <div className="payment-buttons">
                        <button onClick={handleOrderDetailsClick} className="order-details-button">
                            Order Details
                        </button>
                        <button onClick={handleHomeClick} className="home-button">
                            Home
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentStatus;
