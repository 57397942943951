import { createSelector, createSlice } from "@reduxjs/toolkit";
import { store } from "./store";

export const chatSlice = createSlice({
    name: "chatData",
    initialState: {
        data: {},
    },
    reducers: {
        setChatData: (state, action) => {
            state.data = action.payload.data;
        },
    },
});

export const { setChatData } = chatSlice.actions;
//  store new user chat data 
export const getChatData = (data) => {
    store.dispatch(setChatData({ data }))
}

export default chatSlice.reducer;


export const newchatData = createSelector(
    (state) => state.chatData,
    (chatData) => chatData.data
);