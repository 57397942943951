// ** Redux Imports
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { store } from "./store";

const initialState = {
  token: null,
  profile: null,
  web_fcm_token: null,
  uid: null,
  email:null,
  phone: null,
  username: null,
  loginType: null,
  countryCode: null,
  formattedNumber: null
}

export const profileSlice = createSlice({
  name: "user_data",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    updateData: (state, action) => {
      let { phone, username, loginType, email, uid, countryCode, formattedNumber } = action.payload;
      state.phone = phone;
      state.uid = uid;
      state.email = email;
      state.username = username;
      state.loginType = loginType;
      state.countryCode = countryCode;
      state.formattedNumber = formattedNumber;
      // state.profile.data.phone = phone;
      // state.profile.data.username = username;
      // state.profile.data.loginType = loginType;
    },
    updateToken: (state, action) => {
      state.web_fcm_token = action.payload.data;
    },
  },
});

export const { setProfile, updateToken, updateData, setToken } =
  profileSlice.actions;

export const getFcmToken = (data) => {
  store.dispatch(updateToken({ data }));
};

export const Fcmtoken = createSelector(
  state => state.UserData,
  UserData => UserData?.fcmToken
);

export default profileSlice.reducer;
