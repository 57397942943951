import { Button } from "@mui/material";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import api from "../API/apiCollection";
import toast from "react-hot-toast";


const FlutterwavePaymentComponent = () => {

  const settings = useSelector((state) => state?.Settings)?.settings
    ?.payment_gateways_settings;
  const orderDetails = useSelector(
    (state) => state.OrderCartDetails
  )?.orderDetails;
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;
  const date = orderDetails && orderDetails.date;
  const time = orderDetails && orderDetails.slot;
  const orderNotes = orderDetails.orderNote;
  const profile = useSelector((state) => state.UserData);
  const dispatch = useDispatch();

  let promocode = useSelector((state) => state.Promocode);
  let selectedPromoCode = promocode.selectedPromoCode;

  const navigate = useNavigate();

  const handlePayout = async () => {
    try {
      const result = await api.placeOrder({
        method: "flutterwave",
        date: date,
        time: time,
        addressId:
          delivery_type === "Home" ? orderDetails?.selectedAddress?.id : "",
        order_note: orderNotes,
        promo_code_id: selectedPromoCode ? selectedPromoCode.id : "",
      });

      if (result.error === false || result.error === "false") {
        const flutterwave_link = result?.data?.flutterwave
        if (flutterwave_link) {
          // Open PayPal in new tab
          window.location.href = flutterwave_link;

        }
      } else {
        if (typeof result.message === "object") {
          Object.values(result.message).forEach((e) => {
            toast.error(e);
          });
        } else {
          toast.error(result.message);
        }
        throw new Error("Failed to place order");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      toast.error("Failed to place order. Please try again later.");
      throw error;
    }
  };


  return (
    <Button
      variant="outlined"
      fullWidth
      sx={{ my: 1 }}
      onClick={handlePayout}
    >
      {t("make_a_payment")} {t("with")} {t("flutterwave")}
    </Button>
  );
};

export default FlutterwavePaymentComponent;
