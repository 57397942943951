import React, { useState, useEffect } from 'react';
import './CustomLightBox.css'; // Ensure this CSS file is loaded
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { placeholderImage } from '../../util/Helper';

const CustomLightBox = ({ lightboxOpen, handleCloseLightbox, currentImages, currentImageIndex }) => {
    const [currentIndex, setCurrentIndex] = useState(currentImageIndex);

    useEffect(() => {
        setCurrentIndex(currentImageIndex);
    }, [currentImageIndex]);

    useEffect(() => {
        // Disable scrolling when lightbox is open
        document.body.style.overflow = lightboxOpen ? 'hidden' : 'auto';

        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                handleCloseLightbox();
            } else if (e.key === 'ArrowLeft') {
                goToPrevious();
            } else if (e.key === 'ArrowRight') {
                goToNext();
            }
        };

        if (lightboxOpen) {
            document.addEventListener('keydown', handleKeyDown);
        }

        // Cleanup scroll lock and event listener on component unmount
        return () => {
            document.body.style.overflow = 'auto';
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [lightboxOpen]);

    const goToPrevious = () => setCurrentIndex((prevIndex) => (prevIndex - 1 + currentImages.length) % currentImages.length);
    const goToNext = () => setCurrentIndex((prevIndex) => (prevIndex + 1) % currentImages.length);

    if (!lightboxOpen || !currentImages.length) return null;

    const currentImage = currentImages[currentIndex]?.src;
    const currentImageName = currentImages[currentIndex]?.alt;
    
    if (!currentImage) return null;

    // Handle clicks outside of the lightbox content
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            handleCloseLightbox();
        }
    };

    return (
        <div className="lightbox-overlay" onClick={handleOverlayClick}>
            <div className="lightbox-modal">
                <div className="lightbox-header">
                    {/* <span>{currentImageName}</span> */}
                    <button onClick={handleCloseLightbox} className="lightbox-close-button">
                        <IoMdClose size={24} />
                    </button>
                </div>
                <div className="lightbox-content">
                    <img src={currentImage} alt={`Image ${currentIndex + 1}`} className="lightbox-image" onError={placeholderImage}/>
                    {currentImages && currentImages?.length > 1 &&
                    <div className="lightbox-controls">
                        <button onClick={goToPrevious} className="lightbox-prev-button">
                            <FaChevronLeft />
                        </button>
                        <button onClick={goToNext} className="lightbox-next-button">
                            <FaChevronRight />
                        </button>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default CustomLightBox;
